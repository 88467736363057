import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {GlobalService} from "../../../global.service";
import {MortgageGlobalService} from "../../../mortgage-global.service";
import {HelperService} from "../../../helper.service";
import {validate} from "codelyzer/walkerFactory/walkerFn";

@Component({
  selector: 'app-mortgage-split-loan-view',
  templateUrl: './motrgage-split-loan-view.component.html',
  styleUrls: ['./motrgage-split-loan-view.component.scss']
})
export class MortgageSplitLoanViewComponent implements OnInit {

  lastSplitLoanUpdated = null;
  buttonDisabled = false;
  split_loan_type = [
    {'loan_type_id': 1, 'loan_type_name': 'Interest Only'},
    {'loan_type_id': 2, 'loan_type_name': 'Principal Interest'},
  ];
  splitLoan: any = [];
  splitMortgageForm: FormGroup;
  @ViewChild('splitModel') splitModel: ModalDirective;
  responseWaiting = '';
  mortgage_ref = null;
  mortgageHeader :any = [];
  variable_redraw_facility = 0;
  fixed_redraw_facility = 0;
  submitted = false;
  loan_start_date = null;
  is_variable_interest_only_loan_start_error = false;
  is_variable_interest_only_loan_term_error = false;
  is_variable_interest_only_loan_end_error = false;
  mortgage_header_data: any = [];
  property_ref = null;
  comefrom = 'mortgage';
  interestOnlyTermValues: any[] = [{ value: '1' }, { value: '3' }, { value: '5' }];
  fixedInterestTermValues: any[] = [{ value: '1' }, { value: '3' }, { value: '5' }];
  baseModel = 'mortgage';
  isComeFromMortgageHeader = false;
  constructor(
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private notifications: NotificationsService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private helperService: HelperService,
    private fb: FormBuilder,private mortgage_service:MortgageGlobalService) {
    this.initForm();
    this.mortgage_service.openSplitLoanModelEvent.subscribe(data => {
      this.mortgage_service.splitLoanModelOpen({
        comeFrom: this.comefrom,
        action:true
      });
      this.splitMortgageForm.reset();
      this.initForm();

      if('comefrom' in data){
        this.comefrom = data['comefrom'];
      }else{
        this.comefrom = 'mortgage';
      }

      if('baseModel' in data){
        this.baseModel = data['baseModel'];
      }
      else{
        this.baseModel = 'mortgage';
      }

      if('update' in data){
        this.isComeFromMortgageHeader = true;
      }
      else {
        this.isComeFromMortgageHeader = false;
      }

      this.mortgageHeader = data['mortgage_header_details'];
      this.property_ref = data['property_ref'];
      console.log(data['property_ref']);
      this.openModal(data);
    });
  }

  ngOnInit(): void {
  }

  private initForm() {
    this.splitMortgageForm = this.fb.group({
      split_loan_ref: [''],
      variable_rate_type: ['Variable'],
      variable_loan_account_number: [null],
      variable_mortgage_type: [1, [Validators.required]],
      total_variable_amount: [null, [Validators.required]],
      variable_interest_rate: [null, [Validators.required]],
      variable_redraw_offset_facility: [1, [Validators.required]],
      variable_current_loan_balance: [null, [Validators.required]],
      variable_redraw_available: [null],
      variable_monthly_repayment: [null, [Validators.required]],
      variable_interest_only_loan_start: [null],
      // variable_interest_only_loan_term: [null],
      variable_interest_only_loan_end: [null],
      fixed_rate_type: ['Fixed'],
      fixed_mortgage_type: [2, [Validators.required]],
      fixed_loan_account_number: [null],
      total_fixed_amount: [null, [Validators.required]],
      fixed_interest_rate: [null, [Validators.required]],
      fixed_redraw_offset_facility: [1, [Validators.required]],
      fixed_current_loan_balance: [null, [Validators.required]],
      fixed_redraw_available: [null],
      fixed_monthly_repayment: [null, [Validators.required]],
      fixed_interest_only_loan_start: [null, [Validators.required]],
      // fixed_interest_only_loan_term: [null, [Validators.required]],
      fixed_interest_only_loan_end: [null, [Validators.required]],
      variable_interest_only_loan_start_str: [null],
      variable_interest_only_loan_end_str: [null],
      fixed_interest_only_loan_start_str: [null],
      fixed_interest_only_loan_end_str: [null],
      updated_at: [null],
      variable_interest_only_loan_term:['0', [this.validateRangeInterestOnly.bind(this)]],
      fixed_interest_only_loan_term:['0', [this.validateRangeFixedInterestOnly.bind(this)]],
    });

  }

  validateRangeInterestOnly(control: FormControl) {
    const enteredValue = parseInt(control.value, 10);
    if (isNaN(enteredValue) || enteredValue < 0 || enteredValue > 30) {
      this.interestOnlyTermValues = [];
      this.interestOnlyTermValues = [{ value: '1' }, { value: '3' }, { value: '5' }];
      return { invalidRange: true };
    }

    return null;
  }

  validateRangeFixedInterestOnly(control: FormControl) {
    const enteredValue = parseInt(control.value, 10);
    if (isNaN(enteredValue) || enteredValue < 0 || enteredValue > 30) {
      this.fixedInterestTermValues = [];
      this.fixedInterestTermValues = [{ value: '1' }, { value: '3' }, { value: '5' }];
      return { invalidRange: true };
    }

    return null;
  }

  async  openModal(data) {
    this.spinner.show();
    this.mortgage_ref = data.mortgage_ref;
    this.globalService.getSplitMortgageDetails(data).subscribe(response=>{
        this.spinner.hide();
        this.mortgageHeader = response['mortgage_header'];
        if(response['split_loan_details'] != null){
          let split_loan_details = response['split_loan_details'];

          if(split_loan_details['variable_interest_only_loan_start'] != null){
            split_loan_details['variable_interest_only_loan_start'] = this.helperService.formDateObject(split_loan_details['variable_interest_only_loan_start']);
          }

          if(split_loan_details['variable_interest_only_loan_end'] != null){
            split_loan_details['variable_interest_only_loan_end'] = this.helperService.formDateObject(split_loan_details['variable_interest_only_loan_end']);
          }

          if(split_loan_details['fixed_interest_only_loan_start'] != null){
            split_loan_details['fixed_interest_only_loan_start'] =this.helperService.formDateObject(split_loan_details['fixed_interest_only_loan_start']);
          }

          if(split_loan_details['fixed_interest_only_loan_end'] != null){
            split_loan_details['fixed_interest_only_loan_end'] = this.helperService.formDateObject(split_loan_details['fixed_interest_only_loan_end']);
          }

          if(split_loan_details['updated_at'] == null){
            split_loan_details['updated_at'] = split_loan_details['created_at'];
          }

          this.splitMortgageForm.patchValue(split_loan_details);
          this.setTwoNumberDecimal('variable_monthly_repayment');
          this.setTwoNumberDecimal('fixed_monthly_repayment');
          this.setTwoNumberDecimal('variable_current_loan_balance');
          this.setTwoNumberDecimal('fixed_current_loan_balance');
          this.setTwoNumberDecimal('total_variable_amount');
          this.setTwoNumberDecimal('total_fixed_amount');
          this.setTwoNumberDecimal('variable_redraw_available');
          this.setTwoNumberDecimal('fixed_redraw_available');
        }
        else {
          this.splitMortgageForm.get('variable_interest_only_loan_start').patchValue(data.loan_start_date);
          this.splitMortgageForm.get('fixed_interest_only_loan_start').patchValue(data.loan_start_date);
          this.splitMortgageForm.get('fixed_rate_type').patchValue('Fixed');
          this.splitMortgageForm.get('variable_rate_type').patchValue('Variable');
          this.splitMortgageForm.get('variable_redraw_offset_facility').patchValue(1);
          this.splitMortgageForm.get('fixed_redraw_offset_facility').patchValue(1);
        }

        if(data.mortgage_ref == null){
          this.splitMortgageForm.get('variable_interest_only_loan_start').patchValue(data.loan_start_date);
          this.splitMortgageForm.get('fixed_interest_only_loan_start').patchValue(data.loan_start_date);
          this.splitMortgageForm.get('fixed_rate_type').patchValue('Fixed');
          this.splitMortgageForm.get('variable_rate_type').patchValue('Variable');
          this.splitMortgageForm.get('variable_redraw_offset_facility').patchValue(1);
          this.splitMortgageForm.get('fixed_redraw_offset_facility').patchValue(1);
        }
      },
      error=>{
        this.spinner.hide();
        this.notifications.error('Error', error,{timeOut: 3000, showProgressBar: false});
      });
    // console.log('hit3')
    // ;
    await  this.splitModel.show();
    let data1 = {
      action:true,
      baseModel:this.baseModel
    };
    this.mortgage_service.hideModels(data1);
    // this.globalService.parentModelSwitch(true);
  }

  get vSplit() {
    return this.splitMortgageForm.controls;
  }

  saveSplitMortgageSubmit(form: FormGroup) {
    try{
      this.submitted = true;
      if (!this.splitMortgageForm.valid) {
        return;
      }
      // this.buttonDisabled = true;

      if(this.splitMortgageForm.get('variable_mortgage_type').value == 1){
        let count = 0;
        if(this.splitMortgageForm.value['variable_interest_only_loan_start']== null||this.splitMortgageForm.value['variable_interest_only_loan_start']== undefined){
          count++;
          this.is_variable_interest_only_loan_start_error = true;
        }
        else {
          this.is_variable_interest_only_loan_start_error = false;
        }

        if(this.splitMortgageForm.value['variable_interest_only_loan_term']== null||this.splitMortgageForm.value['variable_interest_only_loan_term']== undefined){
          count++;
          this.is_variable_interest_only_loan_term_error = true;
        }
        else {
          this.is_variable_interest_only_loan_term_error = false;
        }

        if(this.splitMortgageForm.value['variable_interest_only_loan_end']== null||this.splitMortgageForm.value['variable_interest_only_loan_end']== undefined){
          count++;
          this.is_variable_interest_only_loan_end_error = true;
        }
        else {
          this.is_variable_interest_only_loan_end_error = false;
        }

        if(count > 0){
          return;
        }
      }

      if(this.splitMortgageForm.value['variable_interest_only_loan_start'] != null){
        this.splitMortgageForm.value['variable_interest_only_loan_start_str'] = this.helperService.extractDateString(this.splitMortgageForm.value['variable_interest_only_loan_start']);
      }

      if(this.splitMortgageForm.value['variable_interest_only_loan_end'] != null){
        this.splitMortgageForm.value['variable_interest_only_loan_end_str'] = this.helperService.extractDateString(this.splitMortgageForm.value['variable_interest_only_loan_end']);
      }

      if(this.splitMortgageForm.value['fixed_interest_only_loan_start'] != null){
        this.splitMortgageForm.value['fixed_interest_only_loan_start_str'] = this.helperService.extractDateString(this.splitMortgageForm.value['fixed_interest_only_loan_start']);
      }

      if(this.splitMortgageForm.value['fixed_interest_only_loan_end'] != null){
        this.splitMortgageForm.value['fixed_interest_only_loan_end_str'] = this.helperService.extractDateString(this.splitMortgageForm.value['fixed_interest_only_loan_end']);
      }

      // let splitTotalLoanAmount = (this.splitMortgageForm.value['total_variable_amount']) + (this.splitMortgageForm.value['total_fixed_amount']);
      // let splitCurrentLoanBalance = (this.splitMortgageForm.value['variable_current_loan_balance']) + (this.splitMortgageForm.value['fixed_current_loan_balance']);
      // let splitRedrawAmount = (this.splitMortgageForm.value['variable_redraw_available'] !=null?this.splitMortgageForm.value['variable_redraw_available']:0) + (this.splitMortgageForm.value['fixed_redraw_available'] !=null?this.splitMortgageForm.value['fixed_redraw_available']:0);
      // let splitMonthlyPayment = (this.splitMortgageForm.value['variable_monthly_repayment']) + (this.splitMortgageForm.value['fixed_monthly_repayment']);
      //
      // this.propertyMortgageForm.patchValue({total_loan_amount: splitTotalLoanAmount});
      // this.propertyMortgageForm.patchValue({current_loan_balance: splitCurrentLoanBalance});
      // this.propertyMortgageForm.patchValue({redraw: splitRedrawAmount});
      // this.propertyMortgageForm.patchValue({monthly_repayments: splitMonthlyPayment});
      //
      // this.propertyMortgageForm.get('lvr').setValue(((splitCurrentLoanBalance / this.mortgageHeader['estimated_value_now']) * 100).toFixed(2));
      // console.log(this.mortgageHeader);
      if(this.mortgageHeader['loan_start_date'] != null){
        this.mortgageHeader.loan_start_date_str = this.mortgageHeader.loan_start_date;
      }
      else {
        this.mortgageHeader.loan_start_date_str = null;
      }
      if(this.mortgageHeader['loan_end_date'] != null){
        this.mortgageHeader.loan_end_date_str = this.mortgageHeader.loan_end_date;
      }
      else {
        this.mortgageHeader.loan_end_date_str = null;
      }

      // if(this.mortgage_ref != null){
      //   this.spinner.show();
      let data = {
        'split_loan_details': this.splitMortgageForm.value,
        // 'mortgage_ref': this.mortgage_ref
        'mortgage_header_details':this.mortgageHeader,
        'property_ref':this.property_ref,
        'comefrom':this.comefrom
      };

      this.globalService.saveSplitMortgage(data).subscribe(response=>{
          this.spinner.hide();
          this.notifications.success('Success', "Split loan saved successfully", {timeOut: 3000, showProgressBar: false});
          let result = {
            'last_updated_at': response['last_updated_at'],
            'mortgage_header': response['mortgage_header'],
            'is_financial_institute_change': response['is_financial_institute_change'],
            'hide_modal':false,
            'comeFrom':this.comefrom
          };
          this.mortgage_service.saveSplitMortgage(result);
          this.splitModel.hide();
          if(this.comefrom == 'my_number_saving'){
            result['hide_modal'] = true;
            this.mortgage_service.returnRedraw(result);
          }

          let data1 = {
            action:false,
            baseModel:this.baseModel
          };
          this.mortgage_service.hideModels(data1);

          setTimeout(() => {
            if(this.isComeFromMortgageHeader){
              this.onModalHidden();
            }
          },1000);
          // this.mortgage_service.hideModels(false);
        },
        error=>{
          this.spinner.hide();
          this.notifications.error('Error', error,{timeOut: 3000, showProgressBar: false});
        });
      // }
      // else{
      //   let result = {
      //     'split_loan_details': this.splitMortgageForm.value,
      //     'last_updated_at':null,
      //   };
      //   this.mortgage_service.saveSplitMortgage(result);
      //   this.splitModel.hide();
      //   this.mortgage_service.hideModels(false);
      // }
    }
    catch (e) {
      this.spinner.hide();
      this.notifications.error('Error', e.message,{timeOut: 3000, showProgressBar: false});
    }
  }

  calculateVariableLoanCurrentBalance() {
    let calculateResult = this.helperService.calculateCurrentLoanBalance(this.splitMortgageForm.get('variable_redraw_offset_facility').value,this.splitMortgageForm.value['total_variable_amount'],this.splitMortgageForm.value['variable_redraw_available']);
    if(calculateResult != null){
      this.splitMortgageForm.patchValue({variable_current_loan_balance:calculateResult});
      this.setTwoNumberDecimal('variable_current_loan_balance');
    }

    this.calculateMonthlyRepayment('Variable');
  }

  calculateVariableRedraw() {
    let result = this.helperService.calculateRedraw(this.splitMortgageForm.get('variable_redraw_offset_facility').value,this.splitMortgageForm.value['total_variable_amount'],this.splitMortgageForm.value['variable_current_loan_balance']);
    this.splitMortgageForm.patchValue({variable_redraw_available:result});

    this.calculateMonthlyRepayment('Variable');
  }

  async variableRedrawFacilityChange(event: any) {
    if(this.splitMortgageForm.get('variable_redraw_offset_facility').value == 0){
      this.splitMortgageForm.patchValue({variable_redraw_available: 0});
      this.setTwoNumberDecimal('fixed_redraw_available');
      await this.calculateVariableLoanCurrentBalance();
    }
  }

  calculateFixedLoanCurrentBalance() {
    let calculateResult = this.helperService.calculateCurrentLoanBalance(this.splitMortgageForm.get('fixed_redraw_offset_facility').value,this.splitMortgageForm.value['total_fixed_amount'],this.splitMortgageForm.value['fixed_redraw_available']);
    if(calculateResult != null){
      this.splitMortgageForm.patchValue({fixed_current_loan_balance:calculateResult});
      this.setTwoNumberDecimal('fixed_current_loan_balance');
      this.calculateMonthlyRepayment('Fixed');
    }
  }

  calculateFixedRedraw() {
    let result = this.helperService.calculateRedraw(this.splitMortgageForm.get('fixed_redraw_offset_facility').value,this.splitMortgageForm.value['total_fixed_amount'],this.splitMortgageForm.value['fixed_current_loan_balance']);
    this.splitMortgageForm.get('fixed_redraw_available').setValue(result);
    this.setTwoNumberDecimal('fixed_redraw_available');
    this.calculateMonthlyRepayment('Fixed');
  }

  async fixedRedrawFacilityChange(event: any) {
    if(this.splitMortgageForm.get('fixed_redraw_offset_facility').value == 0){
      this.splitMortgageForm.patchValue({fixed_redraw_available: 0});
      await this.calculateFixedLoanCurrentBalance();
    }
  }

  calculateVariableInterestLoanEndDate(interest_only_term: any, LoanDate: any) {
    if(interest_only_term != null && LoanDate != null){
      let endDate = this.helperService.calculateLoanEndDate(interest_only_term,LoanDate);
      this.splitMortgageForm.patchValue({variable_interest_only_loan_end: endDate});
    }
  }

  calculateFixedInterestLoanEndDate(interest_only_term: any, LoanDate: any) {
    if(interest_only_term != null && LoanDate != null){
      let endDate = this.helperService.calculateLoanEndDate(interest_only_term,LoanDate);
      this.splitMortgageForm.patchValue({fixed_interest_only_loan_end: endDate});
    }
  }

  closeModel() {
    this.splitModel.hide();
    setTimeout(() => {
      if(this.isComeFromMortgageHeader){
        this.onModalHidden();
      }
    },1000);


    if(this.comefrom == 'my_number_saving'){
      let result = {
        'hide_modal':true
      };

      this.mortgage_service.returnRedraw(result);

    }

    let data1 = {
      action:false,
      baseModel:this.baseModel
    };
    this.mortgage_service.hideModels(data1);
    this.globalService.parentModelSwitch(false);
  }

  setTwoNumberDecimal(field) {
    if(this.splitMortgageForm.get(field).value != null && this.splitMortgageForm.get(field).value != ''){
      let resultValue = this.splitMortgageForm.get(field).value;
      resultValue = this.globalService.validateNumberFormat(resultValue);
      this.splitMortgageForm.get(field).setValue(resultValue);
    }
    //
    // let regexp = new RegExp('^(\\d{1,5}|\\d{0,5}\\.\\d{1,2})$');
    // let result =  regexp.test(this.propertyMortgageForm.get(field).value);
    // if(result == false){
    //   let repayment = (this.propertyMortgageForm.get(field).value).toFixed(2);
    //   this.propertyMortgageForm.get(field).patchValue(repayment);
    // }
  }

  onModalHidden() {
    const modalBackdrops = document.querySelectorAll('.modal-backdrop');
    if (modalBackdrops) {
      document.body.classList.remove('modal-open');
      modalBackdrops.forEach(backdrop => {
        backdrop.remove();
      });
    }
  }

  calculateMonthlyRepayment(type: string) {
    let loanBalance = null;
    let interestRate = null;
    let mortgage_type = null;
    if(type == 'Variable'){
      loanBalance = this.splitMortgageForm.get('variable_current_loan_balance').value;
      interestRate = this.splitMortgageForm.get('variable_interest_rate').value;
      mortgage_type = this.splitMortgageForm.get('variable_mortgage_type').value;
      if((loanBalance != null && loanBalance != '') && (interestRate != null && interestRate != '') && (mortgage_type != null && mortgage_type != '')){
        let monthlyRepayment = this.globalService.calculateMonthlyRepayment(loanBalance, interestRate, mortgage_type != null?mortgage_type:1,this.mortgageHeader['loan_term']);
        if(type == 'Variable'){
          this.splitMortgageForm.patchValue({variable_monthly_repayment:  monthlyRepayment == 0?null:monthlyRepayment});
          this.setTwoNumberDecimal('variable_monthly_repayment');
        }
      }
    }

    if(type == 'Fixed'){
      loanBalance = this.splitMortgageForm.get('fixed_current_loan_balance').value;
      interestRate = this.splitMortgageForm.get('fixed_interest_rate').value;
      mortgage_type = this.splitMortgageForm.get('fixed_mortgage_type').value;
      if((loanBalance != null && loanBalance != '') && (interestRate != null && interestRate != '') && (mortgage_type != null && mortgage_type != '')){
        let monthlyRepayment = this.globalService.calculateMonthlyRepayment(loanBalance, interestRate, mortgage_type != null?mortgage_type:1,this.mortgageHeader['loan_term']);
        if(type == 'Fixed'){
          this.splitMortgageForm.patchValue({fixed_monthly_repayment: monthlyRepayment == 0?null:monthlyRepayment});
          this.setTwoNumberDecimal('fixed_monthly_repayment');
        }
      }
    }
  }
}
