import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {GlobalService} from "../../../../shared/global.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {SupplierGlobalService} from "../../../../shared/supplier-global.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-supplier-lookup-list',
  templateUrl: './supplier-lookup-list.component.html',
  styleUrls: ['./supplier-lookup-list.component.scss']
})
export class SupplierLookupListComponent implements OnInit {

  supplierFilterForm:FormGroup;
  pageNo = 1;
  totalCount = 0;
  pageSize = 5;
  supplierTypes:any = [];
  locations:any = [];
  suppliers:any = [];
  hiddenModal = false;
  property_ref = null;
  comeFrom = null;
  key = null;
  @ViewChild('supplierListModel') supplierListModel: ModalDirective;
  constructor(private globalService: GlobalService,
              private spinner: NgxSpinnerService,
              private notifications: NotificationsService,
              private fb: FormBuilder,
              private modalService: BsModalService,
              private supplierGlobalService:SupplierGlobalService,
              private translate: TranslateService,) {
    this.initSupplierFilterForm();
    this.supplierGlobalService.openSupplierListModelEvent.subscribe(data => {
      this.supplierFilterForm.reset();
      if('key' in data){
        this.key = data['key'];
      }
      else{
        this.key = null;
      }

      this.comeFrom = data['comeFrom'];

      this.property_ref = data['property_ref'];

      if(data.supplier_type != null){
        let type_array = [];
        type_array.push(data.supplier_type);
        this.supplierFilterForm.patchValue({supplier_type:type_array});
      }
      else {
        this.supplierFilterForm.patchValue({supplier_type:['all']});
      }

      this.openSupplierListModel();
    });

    this.supplierGlobalService.newSupplierModelCloseEvent.subscribe(data => {
      this.loadSupplierList();
      this.hiddenModal = false;
    });

  }

  initSupplierFilterForm(){
    this.supplierFilterForm = this.fb.group({
      company_name: null,
      supplier_type: null,
      location: null,
    });
  }

  get formValues() {
    return this.supplierFilterForm.controls;
  }


  ngOnInit(): void {
  }

  private openSupplierListModel() {
    let data = {
      'form_data': this.supplierFilterForm.value,
      'page_no': this.pageNo,
      'page_size': this.pageSize,
      'property_ref': this.property_ref,
    };
    this.spinner.show();
    this.globalService.lookupSupplierFilter(data).subscribe(response=>{
      this.supplierTypes = response['supplier_types'];
      this.suppliers = response['suppliers'];
      this.locations = response['locations'];
      this.totalCount = response['total'];
      this.supplierFilterForm.patchValue({location:response['state']});
      this.spinner.hide();
    },error=>{
      this.spinner.hide();
      this.onError(error['error']['message']);
    });


    this.supplierGlobalService.customizeSupplier(true);
    this.supplierListModel.show();
  }

  openAddNewSupplierModel() {
    // this.hiddenModal = true;
    let supplier_type = null;
    let supplier_type_id = null;
    if(this.comeFrom == 'rental'){
      supplier_type_id = 8;
      supplier_type = 'Real Estate Agent';
    }
    if(this.comeFrom == 'mortgage'){
      supplier_type_id = 7;
      supplier_type = 'Banks & Financial Institutions'
    }
    if(this.comeFrom == 'insurance'){
      supplier_type_id = 6;
      supplier_type = 'Insurance';
    }

    let data = {
      'comeFrom': this.comeFrom,
      'supplier_type': supplier_type,
      'supplier_type_id': supplier_type_id,
      'property_ref':this.property_ref,
    }

    if(this.key != null){
      data['key'] = this.key;
    }

    this.supplierGlobalService.openAddNewSupplierModel(data);
    this.supplierListModel.hide();
  }


  clearFilter() {
    this.pageNo = 1;
    this.pageSize = 5;
    this.supplierFilterForm.reset();
    this.supplierFilterForm.patchValue({supplier_type:['all']});
    this.supplierFilterForm.patchValue({location:'all'});
    this.loadSupplierList();
  }

  selectSupplier(supplier: any) {
    let data = {
      'supplier': supplier,
      'comeFrom': this.comeFrom,
      'isDirect':false
    }

    if(this.key != null){
      data['key'] = this.key;
    }

    this.supplierGlobalService.selectSupplier(data);
    this.closeSupplierListModel();
  }

  pageChanged(event) {
    this.pageNo = event.page;
    this.loadSupplierList();
  }

  pageSizeChange(event: any) {
    this.pageSize = event;
    this.loadSupplierList();
  }

  onInfo(msg) {
    this.notifications.create(this.translate.instant('alert.info'), msg, NotificationType.Info, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onWarning(msg) {
    this.notifications.create(this.translate.instant('alert.warning'), msg, NotificationType.Warn, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  loadSupplierList(){
    let data = {
      'form_data': this.supplierFilterForm.value,
      'page_no': this.pageNo,
      'page_size': this.pageSize,
      'property_ref': this.property_ref,
    };
    this.spinner.show();
    this.globalService.lookupSupplierFilter(data).subscribe(response=>{
      this.supplierTypes = response['supplier_types'];
      this.suppliers = response['suppliers'];
      this.totalCount = response['total'];
      this.spinner.hide();
    },error=>{
      this.spinner.hide();
      this.onError(error['error']['message']);
    });
  }


  closeSupplierListModel() {
    this.pageNo = 1;
    this.pageSize = 5;
    this.supplierFilterForm.reset();
    this.supplierListModel.hide();
    this.supplierGlobalService.customizeSupplier(false);
  }

  categoryChange() {
    let category = this.supplierFilterForm.value['supplier_type'];
    if(category.length> 0){
    for(let i = 0;i < category.length;i++) {
        if(this.supplierFilterForm.value['supplier_type'][i] == 'all'){
          this.supplierFilterForm.patchValue({supplier_type:null});
          this.supplierFilterForm.patchValue({supplier_type:['all']});
         break;
        }
      }
    }
  }
}
