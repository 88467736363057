// import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
// const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  badge?: boolean;
  to: string;
  newWindow?: boolean;
  isVisible?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
  permission?: boolean;
  permissions?: string[];
  // snapShot_validation?: boolean;
}

const data: IMenuItem[] = [
  {
    icon: 'glyph-icon simple-icon-home',
    label: 'My Property',
    to: `/app/properties/list`,
    badge: true,
    isVisible: true,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: []
  },
  {
    icon: 'glyph-icon simple-icon-list',
    label: 'List Properties',
    to: `/app/properties`,
    permission: true,
    isVisible: true,
    permissions: ['property_list'],
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-briefcase',
        label: 'Snapshot',
        to: `/app/properties/snapshot`,
        // roles: [UserRole.Admin],
        isVisible: true,
      },

      {
        icon: 'simple-icon-briefcase',
        label: 'Property Details',
        to: `/app/properties/details`,
        isVisible: true,
        // roles: [UserRole.Admin],
      },

      {
        icon: 'simple-icon-pie-chart',
        label: 'Rent',
        to: `/app/properties/rental`,
        isVisible: true,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-basket-loaded',
        label: 'Mortgage',
        to: `/app/properties/mortgage`,
        isVisible: true,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-event',
        label: 'Expenses',
        to: `/app/properties/expense`,
        isVisible: true,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-notebook',
        label: 'Documents',
        to: `/app/properties/documents-new`,
        isVisible: true,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-organization',
        label: 'Assets',
        to: `/app/properties/assets-details`,
        isVisible: true,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-arrow-down-circle',
        label: 'Depreciation',
        to: `/app/properties/depreciation`,
        isVisible: true,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-puzzle',
        label: 'Issues',
        to: `/app/properties/property-issues`,
        isVisible: true,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-diamond',
        label: 'Insurances',
        to: `/app/properties/property-insurances`,
        isVisible: true,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-picture',
        label: 'Pictures',
        to: `/app/properties/photos`,
        isVisible: true,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-rocket',
        label: 'Suppliers',
        to: `/app/properties/suppliers`,
        isVisible: true,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-note',
        label: 'Todo List',
        to: `/app/properties/property-todo-list`,
        isVisible: true,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-info',
        label: 'Other Info',
        to: `/app/properties/property-other-info`,
        isVisible: true,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
    ],
  },
  // {
  //   icon: 'iconsminds-calculator',
  //   label: 'Calculators',
  //   to: `/app/calculators/`,
  //   isVisible: true,
  //   subs: [
  //     {
  //       icon: 'simple-icon-pie-chart',
  //       isVisible: true,
  //       label: 'Interest Rate',
  //       to: '/app/calculators/interest-rate-calculator',
  //       subs: []
  //     },
  //     {
  //       icon: 'simple-icon-chart',
  //       isVisible: true,
  //       label: 'Portfolio Simulator',
  //       to: '/app/calculators/portfolio-scenario-calculator',
  //       subs: []
  //     },{
  //       icon: 'simple-icon-grid',
  //       isVisible: true,
  //       label: 'Can I Afford Calc',
  //       to: '/app/calculators/can-i-afford-calculator',
  //       subs: []
  //     },
  //     {
  //       icon: 'iconsminds-dollar',
  //       isVisible: true,
  //       label: 'Sell Property',
  //       to: '/app/calculators/sell-my-property',
  //       subs: []
  //     },
  //   ],
  // },

  {
    icon: 'simple-icon-speech',
    label: 'Learn Proppel',
    to: `/app/learn-proppel/`,
    isVisible: true,
    subs: [
      {
        icon: 'simple-icon-pie-chart',
        isVisible: true,
        label: 'Tutorial Article',
        to: '/app/learn-proppel/learning-article',
        subs: []
      },
      {
        icon: 'simple-icon-chart',
        isVisible: false,
        label: 'Tutorial Video',
        to: '/app/learn-proppel/',
        subs: []
      },
    ],
  },

/** HIDE KNOWLEDGE */
// TODO REMOVE KNOWLEDGE
  // {
  //   icon: 'iconsminds-pantone',
  //   isVisible: false,
  //   label: 'Knowledge',
  //   to: `/app/knowledge/knowledge`,
  //   subs: []
  // },
  {
    icon: 'simple-icon-book-open',
    isVisible: true,
    label: 'EOFY Tax Report',
    to: `/app/eofy-reports`,
    subs: []
  },
  {
    icon: 'simple-icon-wrench',
    isVisible: true,
    label: 'Proppel Issues',
    to: `/app/proppel-issue`,
    subs: []
  },
  {
    icon: 'iconsminds-letter-open',
    isVisible: true,
    label: 'Proppel Policies',
    to: `/app/proppel-policies`,
    subs: []
  },
  {
    icon: 'iconsminds-sync',
    isVisible: true,
    label: 'Comparisons',
    to: `/app/comparison/compare/new`,
    subs: []
  },
  {
    icon: 'simple-icon-grid',
    isVisible: true,
    label: 'Can I Afford Calc',
    to: '/app/calculators/can-i-afford-calculator',
    subs: []
  },

  {
    icon: 'iconsminds-bar-chart-4',
    isVisible: true,
    label: 'Interest Rate Calc',
    to: '/app/calculators/interest-rate-calculator',
    subs: []
  },
  {
    icon: 'iconsminds-dollar',
    isVisible: true,
    label: 'Sell Property',
    to: '/app/calculators/sell-my-property',
    subs: []
  },
  {
    icon: 'simple-icon-chart',
    isVisible: true,
    label: 'Portfolio Simulator',
    to: '/app/calculators/portfolio-scenario-calculator',
    subs: []
  },


  // {
  //   icon: 'iconsminds-sync',
  //   isVisible: true,
  //   label: 'Notification',
  //   to: `/app/notification/notification-list`,
  //   subs: []
  // }
];

const labelToRemove = 'Snapshot';

// Filter the menu items
// const filteredData: IMenuItem[] = data.map(menuItem => {
//   // const snapShot_validation = true;
//   // if (menuItem.label === 'List Properties' && menuItem.subs) {
//   //   // Filter out the item with the specified label
//   //   if (!snapShot_validation) {
//   //     menuItem.subs = menuItem.subs.filter(subItem => subItem.label !== labelToRemove);
//   //   }
//   // }
//   return menuItem;
// });

// Function to retrieve property count from localStorage
// function getPropertyCountFromLocalStorage(): number {
//   const propertyCount = localStorage.getItem('property_count');
//   console.log('propertyCount', propertyCount);
//   return propertyCount ? parseInt(propertyCount, 10) : 0;
// }
//
// // Check if user has 2 or more active properties
// function hasTwoOrMoreActiveProperties(): boolean {
//   return getPropertyCountFromLocalStorage() >= 22;
// }

// Filter the menu items
const filteredData: IMenuItem[] = data.map(menuItem => {
  // if (menuItem.label === 'Calculators' && menuItem.subs) {
  //   menuItem.subs = menuItem.subs.map(subItem => {
  //     if (subItem.label === 'Portfolio Simulator') {
  //       // Set visibility based on active properties
  //       subItem.isVisible = hasTwoOrMoreActiveProperties();
  //     }
  //     return subItem;
  //   });
  // }
  return menuItem;
});
export default filteredData;

