<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>
<div bsModal #propertyExpenseModel="bs-modal" class="modal fade  {{hiddenModal ? 'hidden' : ''}}" tabindex="-1" [config]="{ ignoreBackdropClick: true}"
     role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-10">
              <h4  class="col-md-12 modal-title pull-left pl-0">Property Purchase Expenses</h4>
              <h6  class="col-md-12 modal-title pull-left pl-0"> {{propertyAddress}}</h6>
              <p class="font-weight-bold text-secondary">
                Move any expenses from the left column that are Purchase Expenses to the Right Column.
              </p>

            </div>
              <div class="d-flex justify-content-end mt-4">
                <button type="button" class="btn btn-primary" (click)="openExpenseModel()">
                  Add New Expense
                </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body ">
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="small"
          color="#fff"
          type="ball-clip-rotate"
          name="propertyExpenseSpinner"
          [fullScreen]="false">
        </ngx-spinner>
        <div class="container">
          <div class="row">
            <!-- All Expenses Section -->
            <div class="col-md-6">
              <div class="p-4 shadow-sm rounded" style="background-color: #d2d2d7;">
                <h4 class="text-primary">All Expenses</h4>
                <ul class="list-group mb-3 expense-list">
                  <li *ngFor="let expense of expenses"
                      class="list-group-item d-flex justify-content-between align-items-center border-0 mb-2"
                      style="background-color: #e3f2fd;">
                    <div>
                      <strong>{{ expense.expense_category }}</strong> - {{ expense.expense_amount | currency }}
                      <p class="mb-1 text-muted" *ngIf="expense.full_name">Supplier: {{ expense.full_name }}</p>
                      <small class="text-secondary">{{ expense.expense_note }}
                        <ng-container *ngIf="expense.expense_date"> -
                          {{ expense.expense_date | date: 'dd/MM/yyyy' }}
                        </ng-container>
                      </small>
                    </div>
                    <a href="javascript:;" class="badge badge-warning mb-1 mr-1" (click)="moveToSelling(expense)" title="Move to Selling">
                      <i  class="glyph-icon simple-icon-arrow-right text-white f-15 "></i>
                    </a>
                  </li>
                </ul>
                <h5 class="text-end text-secondary">Total: {{ calculateTotal(expenses) | currency }}</h5>
              </div>
            </div>

            <!-- Selling Expenses Section -->
            <div class="col-md-6">
              <div class="p-4 shadow-sm rounded" style="background-color: #fff3e0;">
                <h4 class="text-danger"> Purchase Expenses</h4>
                <ul class="list-group mb-3 expense-list">
                  <li *ngFor="let sellingExpense of sellingExpenses; let key = index"
                      class="list-group-item d-flex justify-content-between align-items-center border-0 mb-2"
                      style="background-color: #ffebee;">
                    <div>
                      <strong>{{ sellingExpense.expense_category }}</strong> - {{ sellingExpense.expense_amount | currency }}
                      <p class="mb-1 text-muted" *ngIf="sellingExpense.full_name">Supplier: {{ sellingExpense.full_name }}</p>
                      <small class="text-secondary">{{ sellingExpense.expense_note }}
                        <ng-container *ngIf="sellingExpense.expense_date"> -
                          {{ sellingExpense.expense_date | date: 'dd/MM/yyyy' }}
                        </ng-container>
                      </small>
                    </div>
                    <ng-container *ngIf="key != 0">
                      <a  href="javascript:;" class="badge badge-warning mb-1 mr-1" (click)="removeFromSelling(sellingExpense)" title="Move to All Expenses">
                        <i  class="glyph-icon simple-icon-arrow-left text-white f-15 "></i>
                      </a>
                      <a  href="javascript:;" class="badge badge-primary mb-1 mr-1" (click)="openEditExpenseData(sellingExpense)">
                        <i  class="glyph-icon simple-icon-note text-white f-15 "></i>
                      </a>
                      <a  href="javascript:;" class="badge badge-danger mb-1 mr-1" (click)="deleteSellExpense(sellingExpense)">
                        <i  class="glyph-icon simple-icon-trash text-white f-15 "></i>
                      </a>
                    </ng-container>
                  </li>
                </ul>
                <h5 class="text-end text-secondary">Total: {{ calculateTotal(sellingExpenses) | currency }}</h5>
              </div>
            </div>
          </div>
        </div>
<!--          </div>-->
<!--          <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">-->
<!--            <div class="table-responsive">-->
<!--              <table class="table table-striped">-->
<!--                <thead>-->
<!--                <tr>-->
<!--                  <th scope="col">Expense</th>-->
<!--                  <th scope="col">Amount</th>-->
<!--                  <th scope="col">Supplier</th>-->
<!--                  <th scope="col">Category</th>-->
<!--                  <th scope="col">Description</th>-->
<!--                  <th></th>-->
<!--                </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                <tr *ngFor="let expenseData of expenseList;let key = index;">-->
<!--                  <td>{{expenseData.expense_date | date: 'dd/MM/yyyy'}}</td>-->
<!--                  <td>$ {{expenseData.expense_amount | number: '1.2-2'}}</td>-->
<!--                  <td>{{expenseData.full_name}}</td>-->
<!--                  <td>{{expenseData.expense_category}}</td>-->
<!--                  <td>{{expenseData.description}}</td>-->
<!--                  <td class="text-right ">-->
<!--                    <a  href="javascript:;" class="badge badge-primary mb-1 mr-1" (click)="openEditExpenseData(expenseData)">-->
<!--                      <i  class="glyph-icon simple-icon-note text-white f-15 "></i>-->
<!--                    </a>-->
<!--                    <a  href="javascript:;" class="badge badge-danger mb-1 mr-1" (click)="deleteSellExpense(expenseData)">-->
<!--                      <i  class="glyph-icon simple-icon-trash text-white f-15 "></i>-->
<!--                    </a>-->
<!--                  </td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--                <tfoot *ngIf="expenseList.length == 0">-->
<!--                <tr>-->
<!--                  <td colspan="3">-->
<!--                    No records found..-->
<!--                  </td>-->
<!--                </tr>-->
<!--                </tfoot>-->
<!--              </table>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">-->
<!--            <div class="pull-right">-->
<!--              <h4 class="pr-3 pull-right f-12">Total Sell Expenses : <span class="text-bold-500">$-->
<!--                {{sales_cost | number : '1.2-2'}}</span></h4>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="modal-footer">
        <button type="button"  class="btn btn-outline-secondary pull-left btn-xs mr-3" (click)="saveCloseSellExpenseModel()">Save & Close</button>
        <button type="button"  class="btn btn-outline-warning pull-left btn-xs mr-3" (click)="CloseSellExpenseModel()">Close</button>
      </div>
    </div>
  </div>
</div>

<div bsModal #addPropertyExpenseModel="bs-modal" class="modal fade" tabindex="-1" [config]="{ ignoreBackdropClick: true}"
     role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-10">
              <h4 *ngIf="!isEdit" class="col-md-12 modal-title pull-left pl-0">Add Property Purchase Expense Details</h4>
              <h4 *ngIf="isEdit" class="col-md-12 modal-title pull-left pl-0">Update Property Sales Expense Details</h4>
              <h6  class="col-md-12 modal-title pull-left pl-0"> {{propertyAddress}}</h6>
            </div>
            <div class="col-md-2">
              <!--              <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close" (click)="close()">-->
              <!--                <span aria-hidden="true">&times;</span>-->
              <!--              </button>-->
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="expenseForm" novalidate>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label class="form-group has-float-label" >
              <div class="input-group ">
                <ng-select [items]="expenseCategories" class="w-100" [searchable]="false"
                           [addTagText]="" aria-describedby="inputGroup-sizing-sm" [typeahead]="false"
                           formControlName="expense_category_id" [hideSelected]="true" [multiple]="false" bindLabel="expense_category"
                           bindValue="expense_category_id">
                </ng-select>
              </div>
              <span> Expense Category <span class="text-danger">*</span></span>
              <div *ngIf="submitted && expenseFormControls.expense_category_id.errors" class="invalid-tooltip">This field is required.</div>
            </label>


            <label class="form-group has-float-label" >
              <div class="input-group ">
                <input class="form-control" placeholder="dd/mm/YYYY" formControlName="payment_date" ngbDatepicker #payment_date="ngbDatepicker"
                       (click)="payment_date.toggle()" >
              </div>
              <span> Paid Date <span class="text-danger">*</span></span>
              <div *ngIf="submitted && expenseFormControls.payment_date.errors" class="invalid-tooltip">This field is required.</div>
            </label>

            <label class="form-group has-float-label has-left-icon-append">
              <div class="input-group">
                <div class="input-group-prepend custom-input-group-append-icon-right-border">
                  <span class="input-group-text custom-input-group-append-icon ">$</span>
                </div>
                <input type="number" class="form-control form-control-sm" formControlName="expense_amount"   >
              </div>
              <div *ngIf="submitted && expenseFormControls.expense_amount.errors" class="invalid-tooltip">This field is required.</div>
              <span>Amount <span class="text-danger">*</span></span>
            </label>
            <label class="form-group has-float-label has-right-icon-append " >
              <div class="input-group">
                <ng-select [items]="supplierList"
                           [addTagText]="'Hit Enter to Add Item'"
                           aria-describedby="inputGroup-sizing-sm"
                           [hideSelected]="true"
                           [multiple]="false"
                           bindLabel="showing_name"
                           bindValue="business_name"
                           [selectOnTab]="true"
                           formControlName="business_name"
                           [addTag]="addNewSupplier"
                           class="w-95"
                           (keyup)="searchValue($event)"
                           (clear)="clearSupplier();"
                           (change)="supplierChange($event,'add-sell-cost')">
                </ng-select>
                <div class="input-group-append" >
                  <button class="btn btn-primary default pl-2 pr-2" style="border-radius: unset!important;" type="button" (click)="openSupplierListModel('add-sell-cost')"><i
                    class="fa fa-binoculars text-white"></i></button>
                </div>
              </div>
              <div *ngIf="submitted && expenseFormControls.business_name.errors" class="invalid-tooltip">This field is required.</div>
              <span>Supplier <span class="text-danger">*</span></span>
            </label>


<!--            <label class="form-group has-float-label has-left-icon-append">-->
<!--              <div class="input-group">-->
<!--                <ng-select [items]="supplierList" -->
<!--                           class="w-100"-->
<!--                           aria-describedby="inputGroup-sizing-sm"-->
<!--                            [hideSelected]="true"-->
<!--                           [multiple]="false"-->
<!--                           bindLabel="showing_name"-->
<!--                           bindValue="business_name" -->
<!--                           formControlName="business_name"-->
<!--                           [addTagText]="'Hit Enter to Add Item'" >-->
<!--                </ng-select>-->
<!--              </div>-->
<!--              <div *ngIf="submitted && expenseFormControls.business_name.errors" class="invalid-tooltip">This field is required.</div>-->
<!--              <span>Supplier <span class="text-danger">*</span></span>-->
<!--            </label>-->

            <label class="form-group has-float-label" >
              <div class="input-group ">
                 <textarea class="form-control" rows="3" aria-describedby="inputGroup-sizing-sm"
                          formControlName="description" style="max-height: unset !important;padding-top: 11px;"></textarea>
              </div>
              <span>Note</span>
            </label>
          </div>
        </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button"  class="btn btn-outline-secondary pull-right btn-xs mr-3" (click)="closeAddSellExpenseModel()">Close</button>
        <app-state-button btnClass="btn btn-primary btn-xs" [currentState]="responseWaiting" message="false"
                          (click)="saveSellExpense()" [isDisabled]="buttonDisabled">Save
        </app-state-button>
      </div>
    </div>
  </div>
</div>




