import {EventEmitter, Injectable, Output} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {GlobalService} from "../../../shared/global.service";
import {NavigationEnd, Router} from "@angular/router";

export interface ISidebar {
  containerClassnames: string;
  menuClickCount: number;
  selectedMenuHasSubItems: boolean;
}


@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  @Output() propertyListMenuShowHideEvent: EventEmitter<string> = new EventEmitter();
  private initialSidebar: ISidebar = {
    containerClassnames: environment.defaultMenuType,
    menuClickCount: 0,
    selectedMenuHasSubItems: environment.defaultMenuType === 'menu-default',
  };
  private sidebar = new BehaviorSubject<ISidebar>(this.initialSidebar);
  subHiddenBreakpoint: number = environment.subHiddenBreakpoint;
  menuHiddenBreakpoint: number = environment.menuHiddenBreakpoint;

  mobileClickCount = 0;
  constructor(private router: Router,
              private globalService: GlobalService) {
    // this.globalService.viewMoodChangeEvent.subscribe(data =>{
    //   if(data == 'default'){
    //     window.location.reload();
    //   }
    // });
  }
  getSidebar(): Observable<ISidebar> {
    return this.sidebar.asObservable();
  }
  changeVal(str: string): void {
    const before = this.sidebar.getValue();
    this.sidebar.next({ ...before, containerClassnames: str });
  }
  setContainerClassnames1(
    clickIndex: number,
    strCurrentClasses: string,
    selectedMenuHasSubItems: boolean
  ): void {
    const currentClasses = strCurrentClasses
      ? strCurrentClasses.split(' ').filter((x) => x !== '')
      : '';
    let nextClasses = '';
    if (!selectedMenuHasSubItems) {
      if (
        currentClasses.includes('menu-default') &&
        (clickIndex % 4 === 0 || clickIndex % 4 === 3)
      ) {
        clickIndex = 1;
      }
      if (currentClasses.includes('menu-sub-hidden') && clickIndex % 4 === 2) {
        clickIndex = 0;
      }
      if (
        currentClasses.includes('menu-hidden') &&
        (clickIndex % 4 === 2 || clickIndex % 4 === 3)
      ) {
        clickIndex = 0;
      }
    }


    if (clickIndex % 4 === 0) {
      if (
        currentClasses.includes('menu-default') &&
        currentClasses.includes('menu-sub-hidden')
      ) {
        nextClasses = 'menu-default menu-sub-hidden1';
      } else if (currentClasses.includes('menu-default')) {
        nextClasses = 'menu-default';
      } else if (currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-sub-hidden';
      } else if (currentClasses.includes('menu-hidden')) {
        nextClasses = 'menu-hidden';
      }
      clickIndex = 0;
    } else if (clickIndex % 4 === 1) {
      if (
        currentClasses.includes('menu-default') &&
        currentClasses.includes('menu-sub-hidden')
      ) {
        // nextClasses = 'menu-default menu-sub-hidden main-hidden sub-hidden test-1';
        // nextClasses = 'menu-default menu-sub-hidden sub-hidden test-1';
        nextClasses = 'menu-default sub-hidden test-1';
      } else if (currentClasses.includes('menu-default')) {
        nextClasses = 'menu-default sub-hidden test-2';
      } else if (currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-sub-hidden main-hidden sub-hidden test-3';
      } else if (currentClasses.includes('menu-hidden')) {
        nextClasses = 'menu-hidden main-show-temporary';
      }
    } else if (clickIndex % 4 === 2) {
      if (
        currentClasses.includes('menu-default') &&
        currentClasses.includes('menu-sub-hidden')
      ) {
        nextClasses = 'menu-default menu-sub-hidden sub-hidden';
      } else if (currentClasses.includes('menu-default')) {
        nextClasses = 'menu-default main-hidden sub-hidden test-4';
      } else if (currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-sub-hidden sub-hidden test-6';
      } else if (currentClasses.includes('menu-hidden')) {
        nextClasses = 'menu-hidden main-show-temporary sub-show-temporary';
      }
    } else if (clickIndex % 4 === 3) {
      if (
        currentClasses.includes('menu-default') &&
        currentClasses.includes('menu-sub-hidden')
      ) {
        nextClasses = 'menu-default menu-sub-hidden sub-show-temporary';
      } else if (currentClasses.includes('menu-default')) {
        nextClasses = 'menu-default sub-hidden test-7';
      } else if (currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-sub-hidden sub-show-temporary';
      } else if (currentClasses.includes('menu-hidden')) {
        nextClasses = 'menu-hidden main-show-temporary';
      }
    }

    if (currentClasses.includes('menu-mobile')) {
      nextClasses += ' menu-mobile';
    }

    // submenu new dev
    const mainMenu1 = document.getElementById('mainMenu1');
    const navFull = document.getElementById('nav_right_full');
    const navHalf = document.getElementById('nav_right_half');
    const menuTitles = document.querySelectorAll('.menu-title');
    const bodyClass = document.body.classList;
    const menuIcon = document.querySelector('.menu-icon');
    const appContainer = document.getElementById('app-container');
    mainMenu1.classList.remove('main-menu-collapsed');

    // nextClasses split by space
    const classMain = nextClasses.split(' ');
    if (classMain.includes('sub-hidden')) {
      mainMenu1.classList.remove('sub-open');
      navFull.classList.add('d-block');
      navHalf.classList.remove('d-block');
      menuTitles.forEach((div) => {
        div.classList.add('d-none');
      });

      this.toggleMenuTitleClass();

    } else {
      mainMenu1.classList.add('sub-open');
      navFull.classList.remove('d-block');
      navHalf.classList.add('d-block');
      menuTitles.forEach((div) => {
        div.classList.add('d-none');
      });
      this.toggleMenuTitleClass();
    }

    let viewType = localStorage.getItem('viewType');

    if(bodyClass.contains('tablet')) {
      // nextClasses = ' menu-default menu-sub-hidden';
      // mainMenu1.classList.add('sub-open');
      mainMenu1.classList.add('main-menu-collapsed');
      //mainmenu collapse and submenu open in property detail page
      this.inPropertyDetail();
      navFull.classList.remove('d-block');
      navHalf.classList.add('d-block');
      menuTitles.forEach((div) => {
        div.classList.add('d-none');
      });
      this.toggleMenuTitleClass();
    }

    if(bodyClass.contains('menu-mobile')) {
      this.inPropertyDetail();
      // nextClasses = ' menu-default menu-sub-hidden';
      menuIcon.classList.add('d-none');
      mainMenu1.classList.add('sub-open');
      navFull.classList.remove('d-block');
      navHalf.classList.add('d-block');
      menuTitles.forEach((div) => {
        div.classList.add('d-none');
      });
      this.toggleMenuTitleClass();
    }

    if(bodyClass.contains('desktop')) {
      menuIcon.classList.remove('d-none');
      menuTitles.forEach((div) => {
        div.classList.remove('d-none');
      });

    }

    if(selectedMenuHasSubItems == true){
      mainMenu1.classList.add('main-menu-collapsed');
    }
    if(mainMenu1.classList.contains('sub-hidden') && bodyClass.contains('desktop')) {
      menuTitles.forEach((div) => {
        div.classList.remove('d-none');
      });
    }
    if(mainMenu1.classList.contains('sub-open') && bodyClass.contains('desktop')) {
     if(mainMenu1.classList.contains('main-menu-collapsed')){
        menuTitles.forEach((div) => {
          div.classList.add('d-none');
        });
     }
     if(bodyClass.contains('menu-collapsed')){
       menuTitles.forEach((div) => {
         div.classList.remove('d-none');
       });
     }
    }



    this.sidebar.next({
      containerClassnames: nextClasses,
      menuClickCount: clickIndex,
      selectedMenuHasSubItems,
    });
  }
  /**
   * new method title disappear bug fix
   * **/
  setContainerClassnames(
    clickIndex: number,
    strCurrentClasses: string,
    selectedMenuHasSubItems: boolean
  ): void {
    const currentClasses = strCurrentClasses
      ? strCurrentClasses.split(' ').filter((x) => x !== '')
      : '';
    let nextClasses = '';

    if (!selectedMenuHasSubItems) {
      if (
        currentClasses.includes('menu-default') &&
        (clickIndex % 4 === 0 || clickIndex % 4 === 3)
      ) {
        clickIndex = 1;
      }
      if (currentClasses.includes('menu-sub-hidden') && clickIndex % 4 === 2) {
        clickIndex = 0;
      }
      if (
        currentClasses.includes('menu-hidden') &&
        (clickIndex % 4 === 2 || clickIndex % 4 === 3)
      ) {
        clickIndex = 0;
      }
    }

    if (clickIndex % 4 === 0) {
      nextClasses = currentClasses.includes('menu-default')
        ? 'menu-default'
        : currentClasses.includes('menu-sub-hidden')
          ? 'menu-sub-hidden'
          : currentClasses.includes('menu-hidden')
            ? 'menu-hidden'
            : '';
    } else if (clickIndex % 4 === 1) {
      nextClasses = currentClasses.includes('menu-default')
        ? 'menu-default sub-hidden'
        : currentClasses.includes('menu-sub-hidden')
          ? 'menu-sub-hidden main-hidden'
          : currentClasses.includes('menu-hidden')
            ? 'menu-hidden main-show-temporary'
            : '';
    } else if (clickIndex % 4 === 2) {
      nextClasses = currentClasses.includes('menu-default')
        ? 'menu-default main-hidden sub-hidden'
        : currentClasses.includes('menu-sub-hidden')
          ? 'menu-sub-hidden sub-hidden'
          : currentClasses.includes('menu-hidden')
            ? 'menu-hidden main-show-temporary sub-show-temporary'
            : '';
    } else if (clickIndex % 4 === 3) {
      nextClasses = currentClasses.includes('menu-default')
        ? 'menu-default sub-show-temporary'
        : currentClasses.includes('menu-sub-hidden')
          ? 'menu-sub-hidden sub-show-temporary'
          : currentClasses.includes('menu-hidden')
            ? 'menu-hidden main-show-temporary'
            : '';
    }

    if (currentClasses.includes('menu-mobile')) {
      nextClasses += ' menu-mobile';
    }

    const mainMenu1 = document.getElementById('mainMenu1');
    const navFull = document.getElementById('nav_right_full');
    const navHalf = document.getElementById('nav_right_half');
    const menuTitles = document.querySelectorAll('.menu-title');
    const bodyClass = document.body.classList;
    const menuIcon = document.querySelector('.menu-icon');

    mainMenu1.classList.remove('menu-default', 'menu-sub-hidden', 'menu-hidden', 'main-menu-collapsed');
    mainMenu1.classList.add(...nextClasses.split(' '));

    if (nextClasses.includes('sub-hidden')) {
      mainMenu1.classList.add('main-menu-collapsed');
      navFull.classList.add('d-block');
      navHalf.classList.remove('d-block');
      menuTitles.forEach((div) => div.classList.add('d-none'));
    } else {
      mainMenu1.classList.remove('main-menu-collapsed');
      navFull.classList.remove('d-block');
      navHalf.classList.add('d-block');
      menuTitles.forEach((div) => div.classList.remove('d-none'));
    }

    if (bodyClass.contains('tablet')) {
      console.log('tablet');
      mainMenu1.classList.add('main-menu-collapsed');
      menuTitles.forEach((div) => div.classList.add('d-none'));
      if(bodyClass.contains('menu-collapsed')){
        mainMenu1.classList.remove('main-menu-collapsed');
        menuTitles.forEach((div) => div.classList.remove('d-none'));
      }
      navFull.classList.remove('d-block');
      navHalf.classList.add('d-block');

    } else if (bodyClass.contains('menu-mobile')) {
      menuIcon.classList.add('d-none');
      mainMenu1.classList.add('sub-open');
      navFull.classList.remove('d-block');
      navHalf.classList.add('d-block');
      menuTitles.forEach((div) => div.classList.add('d-none'));
    } else if (bodyClass.contains('desktop')) {
      menuIcon.classList.remove('d-none');
      menuTitles.forEach((div) => {
        div.classList.remove('d-none');
      });

      // Ensure 'menu-mobile' is removed in desktop mode
      if (mainMenu1.classList.contains('menu-mobile')) {
        mainMenu1.classList.remove('menu-mobile');
      }
      if(mainMenu1.classList.contains('main-menu-collapsed')){
        mainMenu1.classList.remove('main-menu-collapsed');
      }
      if(selectedMenuHasSubItems == true){
        mainMenu1.classList.add('main-menu-collapsed');
      }
      if (!selectedMenuHasSubItems){
        mainMenu1.classList.remove('sub-open');
      }

      if (selectedMenuHasSubItems){
        mainMenu1.classList.add('sub-open');
      }

      // Handle cases where 'main-menu-collapsed' affects visibility
      if (mainMenu1.classList.contains('main-menu-collapsed')) {
        menuTitles.forEach((div) => {
          div.classList.add('d-none');
        });
      }

      if (bodyClass.contains('menu-collapsed')) {
        mainMenu1.classList.remove('main-menu-collapsed');
        menuTitles.forEach((div) => {
          div.classList.remove('d-none');
        });
      }
    }

    if (selectedMenuHasSubItems) {
      mainMenu1.classList.add('main-menu-collapsed');
    }

    this.sidebar.next({
      containerClassnames: nextClasses,
      menuClickCount: clickIndex,
      selectedMenuHasSubItems,
    });
  }

  toggleMenuTitleClass() {
    const bodyClass = document.body.classList;
    const menuTitles = document.querySelectorAll('.menu-title');
    if(bodyClass.contains('menu-collapsed')) {
      menuTitles.forEach((div) => {
        div.classList.remove('d-none');
      });
    } else {
      menuTitles.forEach((div) => {
        div.classList.add('d-none');
      });
    }
  }

  addContainerClassname(classname: string, strCurrentClasses: string): void {
    const newClasses = !(strCurrentClasses.indexOf(classname) > -1)
      ? strCurrentClasses + ' ' + classname
      : strCurrentClasses;
    const before = this.sidebar.getValue();
    this.sidebar.next({ ...before, containerClassnames: newClasses });
  }

  changeDefaultClassnames(strCurrentClasses: string): void {
    const before = this.sidebar.getValue();
    this.sidebar.next({ ...before, containerClassnames: strCurrentClasses });
  }

  changeSelectedMenuHasSubItems(hasSubMenu: boolean = true): void {
    const before = this.sidebar.getValue();
    this.sidebar.next({ ...before, selectedMenuHasSubItems: hasSubMenu });
  }

  clickOnMobileMenu = (strCurrentClasses: string) => {

    this.mobileClickCount++;
    if(this.mobileClickCount == 4){
      this.mobileClickCount = 0;
    }


    const before = this.sidebar.getValue();
    const currentClasses = strCurrentClasses
      ? strCurrentClasses
        .split(' ')
        .filter((x) => x !== '' && x !== 'sub-show-temporary')
      : [];
    let nextClasses = '';


    // if (currentClasses.includes('main-show-temporary')) {
    //   nextClasses = currentClasses
    //     .filter((x) => x !== 'main-show-temporary')
    //     .join(' ');
    // } else {
    //   nextClasses = currentClasses.join(' ') + '  main-show-temporary';
    // }

    if(this.mobileClickCount > 0){
      nextClasses = currentClasses.join(' ') + '  main-show-temporary';
    } else {
      nextClasses = currentClasses
        .filter((x) => x !== 'main-show-temporary')
        .join(' ');
    }

    const body = document.body;
    if(this.mobileClickCount == 2){
      // remove d-none from menu titles .menu-title
      const menuTitles = document.querySelectorAll('.menu-title');
      menuTitles.forEach((div) => {
        div.classList.remove('d-none');
      });
      body.classList.add('menu-collapsed');
    } else if(this.mobileClickCount == 3) {
      // add d-none from menu titles .menu-title
      const menuTitles = document.querySelectorAll('.menu-title');
      menuTitles.forEach((div) => {
        div.classList.add('d-none');
      });
      body.classList.remove('menu-collapsed');
    }



    this.sidebar.next({
      ...before,
      containerClassnames: nextClasses,
      menuClickCount: 0,
    });

  }

  clickOnDeskTopMenu = (strCurrentClasses: string,menuHalfShow:boolean) => {
    console.log('menuHalfShow2134234');
    let classes = strCurrentClasses ? strCurrentClasses.split(' '):[];
    let isHasSubMenu = classes.includes('sub-hidden');
    if(isHasSubMenu){
      if(menuHalfShow){
        this.mobileClickCount = 2;
        strCurrentClasses = 'menu-default sub-hidden test-1  main-show-temporary';
      }
      else {
        this.mobileClickCount = 3;
        strCurrentClasses = 'menu-default sub-hidden test-1 main-show-temporary main-show-temporary';
      }
    }
    else {
      this.mobileClickCount ++;
    }

    if(this.mobileClickCount == 4){
      this.mobileClickCount = 0;
    }


    const before = this.sidebar.getValue();
    const currentClasses = strCurrentClasses
      ? strCurrentClasses
        .split(' ')
        .filter((x) => x !== '' && x !== 'sub-show-temporary')
      : [];
    let nextClasses = '';


    // if (currentClasses.includes('main-show-temporary')) {
    //   nextClasses = currentClasses
    //     .filter((x) => x !== 'main-show-temporary')
    //     .join(' ');
    // } else {
    //   nextClasses = currentClasses.join(' ') + '  main-show-temporary';
    // }

    if(this.mobileClickCount > 0){
      nextClasses = currentClasses.join(' ') + '  main-show-temporary';
    } else {
      nextClasses = currentClasses
        .filter((x) => x !== 'main-show-temporary')
        .join(' ');
    }
    const mainMenu1 = document.getElementById('mainMenu1');
    const body = document.body;
    if(this.mobileClickCount == 2){
      // remove d-none from menu titles .menu-title
      const menuTitles = document.querySelectorAll('.menu-title');
      menuTitles.forEach((div) => {
        div.classList.remove('d-none');
      });
      body.classList.add('menu-collapsed');
      mainMenu1.classList.remove('main-menu-collapsed');
    } else if(this.mobileClickCount == 3) {
      // add d-none from menu titles .menu-title
      const menuTitles = document.querySelectorAll('.menu-title');
      menuTitles.forEach((div) => {
        div.classList.add('d-none');
      });
      body.classList.remove('menu-collapsed');
      mainMenu1.classList.add('main-menu-collapsed');
    }



    this.sidebar.next({
      ...before,
      containerClassnames: nextClasses,
      menuClickCount: 0,
    });

  }

  propertyListMenuShowHide(data){
    this.propertyListMenuShowHideEvent.emit(data);
  }



  inPropertyDetail (){
    const bodyClass = document.body.classList;
    const menuTitles = document.querySelectorAll('.menu-title');
    const detailView = document.querySelector('.detail-view');
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const detailView = document.querySelector('.detail-view');
        const urlContainProperty = /app\/properties\/(.*)/;
        const urlNotContainProperty = /app\/properties\/(?!list)(.*)/;
        const url = e.url;

        detailView.classList.remove('menu-detail-padding');
        if (urlContainProperty.test(url)) {
          bodyClass.contains('menu-collapsed') ? bodyClass.remove('menu-collapsed') : '';
          menuTitles.forEach((div) => {
            div.classList.add('d-none');
          });

          detailView.classList.add('detail-view-padding');
        } else {
          detailView.classList.remove('detail-view-padding');
         // bodyClass.contains('menu-collapsed') ? '' : bodyClass.add('menu-collapsed');
        }

      }
    });
  }
}
