import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "./global.service";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PropertyGlobalService {
  @Output() openPropertyDetailsViewModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() loadPropertyDetailsEvent: EventEmitter<any> = new EventEmitter();
  @Output() openPropertyPurposeModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() closePropertyPurposeModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() parentModelSwitchEvent: EventEmitter<any> = new EventEmitter();
  @Output() openBuildingMaterialModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() closeBuildingMaterialModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() openRoofMaterialModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() closeRoofMaterialModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() loadPropertyImageViewEvent: EventEmitter<any> = new EventEmitter();
  @Output() propertyImageViewModelCloseEvent: EventEmitter<any> = new EventEmitter();
  @Output() closePropertyDetailsViewEvent: EventEmitter<any> = new EventEmitter();
  @Output() propertyListInListViewEvent: EventEmitter<any> = new EventEmitter();
  @Output() openPropertySalesExpenseModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() salesExpenseModelCloseEvent: EventEmitter<any> = new EventEmitter();
  @Output() openPropertyShareModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() loadPropertyListChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() openHiddenPropertyModalEvent: EventEmitter<any> = new EventEmitter();
  @Output() openSharedModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() getPropertyReloadEvent: EventEmitter<any> = new EventEmitter();
  @Output() openPropertyPurchaseExpenseModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() purchaseExpenseModelCloseEvent: EventEmitter<any> = new EventEmitter();
  private callAssetDetails = new Subject<string>();
  assetDetails$ = this.callAssetDetails.asObservable();
  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) { }

  openPropertyDetails(data) {
    this.openPropertyDetailsViewModelEvent.emit(data);
  }

  loadPropertyDetails(data) {
    this.loadPropertyDetailsEvent.emit(data);
  }

  openPropertyPurposeModel(data) {
    this.openPropertyPurposeModelEvent.emit(data);
  }
  closePropertyPurposeModel(data) {
    this.closePropertyPurposeModelEvent.emit(data);
  }

  parentModelSwitch(data) {
    this.parentModelSwitchEvent.emit(data);
  }

  openBuildingMaterialModel() {
    this.openBuildingMaterialModelEvent.emit();
  }

  closeBuildingMaterialModel(data) {
    this.closeBuildingMaterialModelEvent.emit(data);
  }

  openRoofMaterialModel() {
    this.openRoofMaterialModelEvent.emit();
  }
  closeRoofMaterialModel(data) {
    this.closeRoofMaterialModelEvent.emit(data);
  }

  loadPropertyImageView(data){
    this.loadPropertyImageViewEvent.emit(data);
  }
  propertyImageViewModelClose(data) {
    this.propertyImageViewModelCloseEvent.emit(data);
  }

  propertyListInListView(data) {
    this.propertyListInListViewEvent.emit(data);
  }

  closePropertyDetailsViewModel(data){
    this.closePropertyDetailsViewEvent.emit(data)
  }

  openPropertySalesExpenseModel(data) {
    this.openPropertySalesExpenseModelEvent.emit(data);
  }

  propertyListChange(data) {
    this.loadPropertyListChangeEvent.emit(data);
  }
  openHiddenPropertyModal(hiddenProperties) {
    this.openHiddenPropertyModalEvent.emit(hiddenProperties);
  }

  openSharePropertyModel(data) {
    this.openPropertyShareModelEvent.emit(data);
  }

  showShareModel(data) {
    this.openSharedModelEvent.emit(data);
  }

  getPropertyReload(data) {
    this.getPropertyReloadEvent.emit(data);
  }
  propertyRegistrationFormLoad() {
    return this.http.get(this.globalService.getAPIUrl() + 'property/property-registration-form-load', this.globalService.getHttpOptions());
  }

  savePropertyPurpose(purpose_id, purpose) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/save-property-purpose?property_purpose_id=' + purpose_id + '&purpose=' + purpose , this.globalService.getHttpOptions());
  }

  checkPropertyPurpose(purpose_id: any) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/delete-property-purpose-validate?property_purpose_id=' + purpose_id , this.globalService.getHttpOptions());
  }

  deletePropertyPurpose(purpose_id: any) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/delete-property-purpose?property_purpose_id=' + purpose_id, this.globalService.getHttpOptions());
  }

  updatePropertyDetails(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/update-property-details', data, this.globalService.getHttpOptions());
  }

  saveBuildingMaterial(id, material) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/save-building-material?building_material_id=' + id + '&building_material=' + material , this.globalService.getHttpOptions());
  }

  checkBuildingMaterial(material_id: any) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/delete-building-material-validate?building_material_id=' + material_id, this.globalService.getHttpOptions());
  }

  deleteBuildingMaterial(material_id: any) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/delete-building-material?building_material_id=' + material_id, this.globalService.getHttpOptions());
  }

  saveRoofMaterial(id, material) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/save-roof-material?roof_material_id=' + id + '&roof_material=' + material, this.globalService.getHttpOptions());
  }

  checkRoofMaterial(material_id: any) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/delete-roof-material-validate?roof_material_id=' + material_id , this.globalService.getHttpOptions());
  }

  deleteRoofMaterial(material_id: any) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/delete-roof-material?roof_material_id=' + material_id , this.globalService.getHttpOptions());
  }

  setPropertyRef(propertyRef: string) {

  }

  salesExpenseModelClose(data){
    this.salesExpenseModelCloseEvent.emit(data);
  }

  callAssetList(data): void {
    this.callAssetDetails.next(data);
  }

  getPermissionList(property_ref) {

    return this.http.get(this.globalService.getAPIUrl() + 'property/get-permission?property_ref=' + property_ref, this.globalService.getHttpOptions());
  }

  openPropertyPurchaseExpenseModel(data) {
    this.openPropertyPurchaseExpenseModelEvent.emit(data);
  }

  purchaseExpenseModelClose(data){
    this.purchaseExpenseModelCloseEvent.emit(data);
  }
}
