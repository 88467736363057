<div bsModal #editMortgageHeaderDetailsModel="bs-modal" class="modal fade shadow-lg {{hiddenModal ? 'hidden' : ''}} " tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true,keyboard: false}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-md-9">
          <h4 class="col-md-12 modal-title pull-left pl-0">Mortgage Details</h4>
        </div>
        <div class="col-md-3 d-flex">
          <button type="button" class="close" aria-label="Close" (click)="closeModel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="propertyMortgageForm"  novalidate>
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <label class="form-group has-float-label" *ngIf="propertyDetails['is_sold'] !=1">
                <ng-select appearance="outline" labelForId="mortgage_type_id" [searchable]="false" [clearable]="false" formControlName="mortgage_type_id" name="mortgage_type_id" required (change)="onMortgageTypeChange($event)" (blur)="calculateMonthlyRepayment()">
                  <ng-option *ngFor="let mortgageType of mortgageTypes" [value]="mortgageType.mortgage_type_id">
                    {{mortgageType.mortgage_type}}
                  </ng-option>
                </ng-select>
                <span>Mortgage Type <span class="text-danger">*</span></span>
                <div *ngIf="submitted && mHeader.mortgage_type_id.errors" class="invalid-tooltip">This field is
                  required.</div>
              </label>

              <label class="form-group has-float-label" *ngIf="propertyMortgageForm.value['mortgage_type_id'] != 2">
                <div class="input-group">
                  <ng-select [items]="financialInstitutes" class="{{comeFrom =='Mortgage' ? 'w-92-prec' : 'w-91-prec'}}"  [addTag]="addFinancialInstitutes"
                             [addTagText]="'Hit Enter to Add Item'" aria-describedby="inputGroup-sizing-sm"
                             name="business_name" [hideSelected]="true" [multiple]="false"
                             bindLabel="showing_name" bindValue="business_name"
                             (keyup)="searchAgent($event);"
                             formControlName="business_name" (change)="itemChange($event)">
                  </ng-select>
                  <div class="input-group-append" >
                    <button class="btn btn-primary default pl-2 pr-2" style="border-radius: unset!important;" type="button" (click)="openSupplierListModel()"><i
                      class="fa fa-binoculars text-white"></i></button>
                  </div>
                </div>
                <span>Financial Institution </span>
              </label>

              <label class="form-group has-float-label">
                <div class="input-group">
                  <input class="form-control" placeholder="dd/mm/YYYY"
                         name="loan_start_date" formControlName="loan_start_date" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()"
                         (blur)="calculateLoanEndDate(propertyMortgageForm.value['loan_term'],propertyMortgageForm.value['loan_start_date']);
                 calculateInterestOnlyEndDate(propertyMortgageForm.value['interest_only_term'],propertyMortgageForm.value['loan_start_date'])">
                </div>
                <span>Loan Start Date</span>
              </label>



              <label class="form-group has-float-label">
                <ng-select
                  [items]="loanTermValues"
                  bindLabel="value"
                  bindValue="value"
                  formControlName="loan_term"
                  [addTag]="true"
                  [multiple]="false"
                  [hideSelected]="true"
                  [clearable]="true"
                  (change)="calculateLoanEndDate(propertyMortgageForm.value['loan_term'],propertyMortgageForm.value['loan_start_date'])" (blur)="calculateMonthlyRepayment()">
                </ng-select>
                <span>Loan Terms (years)</span>
                <div *ngIf="propertyMortgageForm.get('loan_term').hasError('invalidRange')" class="text-danger">
                  Invalid range. Please enter a number between 1 and 50.
                </div>
              </label>

              <label class="form-group has-float-label">
                <div class="input-group">
                  <input class="form-control" placeholder="dd/mm/YYYY"
                         name="loan_end_date" formControlName="loan_end_date" ngbDatepicker #l_end="ngbDatepicker" (click)="l_end.toggle()" disabled>
                </div>
                <span>Loan End Date</span>
              </label>



              <label class="form-group has-float-label" *ngIf="propertyMortgageForm.value['mortgage_type_id'] == 1">
                <ng-select
                  [items]="interestOnlyTermValues"
                  bindLabel="value"
                  bindValue="value"
                  formControlName="interest_only_term"
                  [addTag]="true"
                  [multiple]="false"
                  [hideSelected]="true"
                  [clearable]="true"
                  (change)="calculateInterestOnlyEndDate(propertyMortgageForm.value['interest_only_term'],propertyMortgageForm.value['loan_start_date'])">
                </ng-select>
                <span>Interest Only Terms (years)</span>
                <div *ngIf="propertyMortgageForm.get('interest_only_term').hasError('invalidRange')" class="text-danger">
                  Invalid range. Please enter a number between 1 and 30.
                </div>
              </label>

              <label class="form-group has-float-label" *ngIf="propertyMortgageForm.value['mortgage_type_id'] == 1">
                <div class="input-group">
                  <input class="form-control" placeholder="dd/mm/YYYY"
                         name="interest_only_end_date" formControlName="interest_only_end_date" ngbDatepicker #i_only_end="ngbDatepicker" (click)="i_only_end.toggle()" disabled>
                </div>
                <span>Interest Only End Date</span>
              </label>


<!--              <label class="form-group has-float-label" *ngIf="propertyMortgageForm.value['mortgage_type_id'] ==2 && propertyMortgageForm.value['rate_type'] == 'Fixed'">-->
<!--                <div class="input-group">-->
<!--                  <input class="form-control" placeholder="dd/mm/YYYY"-->
<!--                         name="fixed_interest_loan_start_date" formControlName="fixed_interest_loan_start_date" ngbDatepicker #fixed_interest_start="ngbDatepicker" (click)="fixed_interest_start.toggle();fixedInterestLoanStartDateError=false"-->
<!--                         (blur)="calculateFixedInterestLoanEndDate(propertyMortgageForm.value['fixed_interest_loan_term'],propertyMortgageForm.value['fixed_interest_loan_start_date'])">-->
<!--                </div>-->
<!--                <span>Fixed Interest Loan Start Date <span class="text-danger">*</span></span>-->
<!--                <div *ngIf="submitted && fixedInterestLoanStartDateError"  class="invalid-tooltip">This field is-->
<!--                  required</div>-->
<!--              </label>-->

              <label class="form-group has-float-label" *ngIf="propertyMortgageForm.value['mortgage_type_id'] == 2  && propertyMortgageForm.value['rate_type'] == 'Fixed'">
                <ng-select
                  [items]="fixedInterestTermValues"
                  bindLabel="value"
                  bindValue="value"
                  formControlName="fixed_interest_loan_term"
                  [addTag]="true"
                  [multiple]="false"
                  [hideSelected]="true"
                  [clearable]="true"
                  (change)="calculateFixedInterestLoanEndDate(propertyMortgageForm.value['fixed_interest_loan_term'],propertyMortgageForm.value['loan_start_date'])"
                  (focus)="fixedInterestLoanTermError=false">
                </ng-select>
                <span>Fixed Interest Loan Terms (years) <span class="text-danger">*</span></span>
                <div *ngIf="propertyMortgageForm.get('fixed_interest_loan_term').hasError('invalidRange')" class="text-danger">
                  Invalid range. Please enter a number between 1 and 30.
                </div>
                <div *ngIf="submitted && fixedInterestLoanStartDateError"  class="invalid-tooltip">This field is
                  required.</div>
              </label>

              <label class="form-group has-float-label" *ngIf="propertyMortgageForm.value['mortgage_type_id'] ==2  && propertyMortgageForm.value['rate_type'] == 'Fixed'">
                <div class="input-group">
                  <input class="form-control" placeholder="dd/mm/YYYY"
                         name="fixed_interest_loan_end_date" formControlName="fixed_interest_loan_end_date"
                         ngbDatepicker #fixed_interest_end="ngbDatepicker" (click)="fixed_interest_end.toggle()" >

                </div>
                <span>Fixed Interest Loan End Date</span>
              </label>


              <div class="col-md-12 pl-0" *ngIf="propertyMortgageForm.value['mortgage_type_id'] != 3">
                <div class="form-group ">
                  <label class="form-group mb-1">Have Redraw / Offset? <span class="text-danger">*</span></label>
                  <div class="row">
                    <div class="form-check ml-3">
                      <input class="form-check-input" type="radio" formControlName="redraw_offset_facility"
                             name="redraw_offset_facility" id="variable_redraw_offset_facility_1"   [value]="1" (change)="redrawFacilityChange($event)" (blur)="calculateMonthlyRepayment()">
                      <label class="form-check-label text-small" for="variable_redraw_offset_facility_1" style="margin-top: 0.1rem;">
                        Yes
                      </label>
                    </div>
                    <div class="form-check ml-3">
                      <input class="form-check-input" type="radio" formControlName="redraw_offset_facility" name="redraw_offset_facility"
                             id="variable_redraw_offset_facility_2"  [value]="0" (change)="redrawFacilityChange($event)" (blur)="calculateMonthlyRepayment()">
                      <label class="form-check-label text-small " for="variable_redraw_offset_facility_2" style="margin-top: 0.1rem;">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div *ngIf="submitted && mHeader.redraw_offset_facility.errors" class="invalid-tooltip">This field is
                  required.</div>
              </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <label class="form-group has-float-label" *ngIf="propertyMortgageForm.value['mortgage_type_id'] == 2">
                <div class="input-group">
                  <ng-select [items]="financialInstitutes" class="{{comeFrom =='Mortgage' ? 'w-92-prec' : 'w-91-prec'}}"  [addTag]="addFinancialInstitutes"
                             [addTagText]="'Hit Enter to Add Item'" aria-describedby="inputGroup-sizing-sm"
                             name="business_name" [hideSelected]="true" [multiple]="false"
                             bindLabel="showing_name" bindValue="business_name"
                             (keyup)="searchAgent($event);"
                             formControlName="business_name" (change)="itemChange($event)">
                  </ng-select>
                  <div class="input-group-append" >
                    <button class="btn btn-primary default pl-2 pr-2" style="border-radius: unset!important;" type="button" (click)="openSupplierListModel()"><i
                      class="fa fa-binoculars text-white"></i></button>
                  </div>
                </div>
                <span>Financial Institution<a href="javascript:;" ><i class="fa fa-pencil text-primary ml-1"></i></a></span>
                <div *ngIf="submitted && mHeader.business_name.errors" class="invalid-tooltip">This field is
                  required.</div>
              </label>

              <label class="form-group has-float-label" *ngIf="propertyMortgageForm.value['mortgage_type_id'] !=3">
                <div class="input-group">
                  <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                         name="loan_account" formControlName="loan_account">
                </div>
                <span>Loan Account Number</span>
              </label>

              <label class="form-group has-float-label" *ngIf="propertyMortgageForm.value['mortgage_type_id'] !=3">
                <ng-select appearance="outline" labelForId="rate_type" [searchable]="false" [clearable]="false" name="rate_type" formControlName="rate_type" (blur)="calculateFixedInterestLoanEndDate(propertyMortgageForm.value['fixed_interest_loan_term'],propertyMortgageForm.value['loan_start_date'])">
                  <ng-option value="Variable">Variable</ng-option>
                  <ng-option value="Fixed">Fixed</ng-option>
                </ng-select>
                <span>Rate Type</span>
              </label>


              <label class="form-group has-float-label has-right-icon-append" *ngIf="propertyMortgageForm.value['mortgage_type_id'] !=3">
                <div class="input-group ">
                  <input type="number" class="form-control border-right-0"   [min]="0" [max]="100"
                         formControlName="loan_interest_rate" (blur)="calculateMonthlyRepayment()">
                  <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">
                    <i class="fa fa-percent f-12 " ></i></span>
                  </div>
                </div>
                <span>Interest Rate</span>
                <div *ngIf="submitted && mHeader.loan_interest_rate.errors" class="invalid-tooltip">Please
                  enter between 1 to 100 number.</div>
              </label>

              <!--      <label class="form-group has-float-label has-right-icon-append" *ngIf="propertyMortgageForm.value['mortgage_type_id'] !=3">-->
              <!--          <div class="input-group">-->
              <!--            <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
              <!--                   [min]="0" [max]="100"-->
              <!--                   formControlName="loan_interest_rate">-->
              <!--          </div>-->
              <!--        <div class="input-group-prepend ">-->
              <!--                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">-->
              <!--                    <i class="fa fa-percent f-12 " ></i></span>-->
              <!--        </div>-->
              <!--        <span>Interest Rate</span>-->
              <!--        <div *ngIf="submitted && mHeader.loan_interest_rate.errors" class="invalid-tooltip">Please-->
              <!--          enter between 1 to 100 number.</div>-->
              <!--      </label>-->

              <label class="form-group has-float-label has-left-icon-append" *ngIf="propertyMortgageForm.value['mortgage_type_id'] !=3">
                <div class="input-group">
                  <div class="input-group-prepend custom-input-group-append-icon-right-border">
                    <span class="input-group-text custom-input-group-append-icon ">$</span>
                  </div>
                  <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                         name="total_loan_amount" formControlName="total_loan_amount"
                         (blur)="calculateCurrentLoanBalance();"
                         (change)="setTwoNumberDecimal('total_loan_amount')">
                </div>
                <span>Total Loan Amount</span>
              </label>


              <label class="form-group has-float-label has-left-icon-append" *ngIf="propertyMortgageForm.value['mortgage_type_id'] !=3">
                <div class="input-group">
                  <div class="input-group-prepend custom-input-group-append-icon-right-border">
                    <span class="input-group-text custom-input-group-append-icon ">$</span>
                  </div>
                  <input type="number" class="form-control"  formControlName="current_loan_balance" name="current_loan_balance"
                         (blur)="calculateRedraw()"
                         (change)="setTwoNumberDecimal('current_loan_balance')"
                  >
                  <div class="input-group-append">
                    <button class="btn btn-primary default pl-2 pr-2" style="border-radius: unset!important;" type="button"
                            (click)="calculateRedraw()"><i class=" glyph-icon simple-icon-link  text-white"></i></button>
                  </div>
                </div>
                <span>Current Loan Balance</span>
              </label>

              <!--      <label class="form-group has-float-label has-left-icon-append" *ngIf="propertyMortgageForm.value['mortgage_type_id'] !=3">-->
              <!--          <div class="input-group">-->
              <!--            <div class="input-group-prepend custom-input-group-append-icon-right-border">-->
              <!--              <span class="input-group-text custom-input-group-append-icon ">$</span>-->
              <!--            </div>-->
              <!--            <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
              <!--                   formControlName="current_loan_balance" name="current_loan_balance"-->
              <!--                   (blur)="calculateRedraw()">-->
              <!--            <div class="input-group-append">-->
              <!--              <button class="btn btn-primary default " type="button"-->
              <!--                      (click)="calculateRedraw()"><i-->
              <!--                class=" fa fa-link  text-white"></i></button>-->
              <!--            </div>-->
              <!--            <div class="form-control-position">-->
              <!--              <span class="input-field-currency-type-size">$</span>-->
              <!--            </div>-->
              <!--          </div>-->
              <!--        <span>Current Loan Balance</span>-->
              <!--      </label>-->

              <label class="form-group has-float-label has-left-icon-append" hidden>
                <div class="input-group">
                  <div class="input-group-prepend custom-input-group-append-icon-right-border">
                    <span class="input-group-text custom-input-group-append-icon ">$</span>
                  </div>
                  <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                         name="amount_paid_off" formControlName="amount_paid_off"
                         (blur)="calculateCurrentLoanBalance()">
                </div>
                <span>Amount Paid off </span>
              </label>

              <label class="form-group  has-float-label has-left-icon-append" *ngIf="propertyMortgageForm.value['mortgage_type_id'] !=3 && this.propertyMortgageForm.get('redraw_offset_facility').value == 1">
                <div class="input-group">
                  <div class="input-group-prepend custom-input-group-append-icon-right-border">
                    <span class="input-group-text custom-input-group-append-icon ">$</span>
                  </div>
                  <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                         name="redraw" formControlName="redraw"
                         (change)="setTwoNumberDecimal('redraw')"
                         (blur)="calculateCurrentLoanBalance();">

                </div>
                <span>Redraw / Offset </span>
              </label>

              <label class="form-group has-float-label has-right-icon-append" hidden>
                <div class="input-group">
                  <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm" readonly
                         name="lvr" formControlName="lvr" [ngStyle]="{ 'background-color':'#e2e6eb'}">
                  <div class="input-group-prepend " [ngStyle]="{ 'background-color':'#e2e6eb'}">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">
                    <i class="fa fa-percent f-12 " ></i></span>
                  </div>
                </div>
                <span>Loan to Value Ratio (LVR) </span>
              </label>

              <label class="form-group has-float-label has-right-icon-append" hidden>
                <div class="input-group">
                  <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                         name="paid_off_rate" formControlName="paid_off_rate">
                  <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">
                    <i class="fa fa-percent f-12 " ></i></span>
                  </div>
                </div>
                <span>% Paid off </span>
              </label>

              <label class="form-group has-float-label has-left-icon-append" *ngIf="propertyMortgageForm.value['mortgage_type_id'] !=3">
                <div class="input-group">
                  <div class="input-group-prepend custom-input-group-append-icon-right-border">
                    <span class="input-group-text custom-input-group-append-icon ">$</span>
                  </div>
                  <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                         name="monthly_repayments" formControlName="monthly_repayments" (change)="setTwoNumberDecimal('monthly_repayments')">
                </div>
                <span>Monthly Payment</span>
              </label>

              <!--      Split Mortgage fields    -->
              <label class="form-group has-float-label has-left-icon-append" *ngIf="propertyMortgageForm.value['mortgage_type_id'] ==3">
                <div class="input-group">
                  <div class="input-group-prepend custom-input-group-append-icon-right-border" [ngStyle]="{ 'background-color':'#e2e6eb'}">
                    <span class="input-group-text custom-input-group-append-icon ">$</span>
                  </div>
                  <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                         name="total_loan_amount" formControlName="total_loan_amount" readonly [ngStyle]="{ 'background-color':'#e2e6eb'}">
                </div>
                <span>Total Loan Amount (Variable & Fixed)</span>
              </label>

              <label class="form-group has-float-label has-left-icon-append" *ngIf="propertyMortgageForm.value['mortgage_type_id'] ==3">
                <div class="input-group">
                  <div class="input-group-prepend custom-input-group-append-icon-right-border" [ngStyle]="{ 'background-color':'#e2e6eb'}">
                    <span class="input-group-text custom-input-group-append-icon ">$</span>
                  </div>
                  <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                         name="current_loan_balance" formControlName="current_loan_balance" readonly [ngStyle]="{ 'background-color':'#e2e6eb'}">
                </div>
                <span>Current Loan Amount (Variable & Fixed)</span>
              </label>


              <label class="form-group has-float-label has-left-icon-append" *ngIf="propertyMortgageForm.value['mortgage_type_id'] ==3">
                <div class="input-group ">
                  <div class="input-group-prepend custom-input-group-append-icon-right-border" [ngStyle]="{ 'background-color':'#e2e6eb'}">
                    <span class="input-group-text custom-input-group-append-icon ">$</span>
                  </div>
                  <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                         name="redraw" formControlName="redraw" readonly [ngStyle]="{ 'background-color':'#e2e6eb'}">
                </div>

                <span>Redraw Available (Variable & Fixed)</span>
              </label>

              <label class="form-group  has-float-label has-left-icon-append" *ngIf="propertyMortgageForm.value['mortgage_type_id'] ==3">
                <div class="input-group">
                  <div class="input-group-prepend custom-input-group-append-icon-right-border" [ngStyle]="{ 'background-color':'#e2e6eb'}">
                    <span class="input-group-text custom-input-group-append-icon ">$</span>
                  </div>
                  <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                         name="monthly_repayments" formControlName="monthly_repayments" readonly [ngStyle]="{ 'background-color':'#e2e6eb'}">
                </div>
                <span>Monthly Payment (Variable & Fixed)</span>
              </label>
              <!--      Split Mortgage fields  End  -->
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2" *ngIf="propertyMortgageForm.value['mortgage_type_id'] == 3">
              <button class="btn btn-outline-primary w-100 " (click)="openSpitLoanModel()">Setup Split Loan Details Here <span>( last updated {{lastSplitLoanUpdated | date:'dd/MM/yyyy'}} )</span></button>
            </div>
          </div>

        </form>
      </div>
      <div class="modal-footer" style="display: block !important;">
        <div class="row pull-right  pl-2 pr-2">
          <button type="button" class="btn btn-outline-primary pull-right mr-2"
                  (click)="closeModel()">Close</button>
          <button type="button" class="btn btn-primary pull-right"
                  (click)="addPropertyMortgageSubmit()">Save</button>

        </div>
      </div>
    </div>
  </div>
</div>
